import 'react-toastify/dist/ReactToastify.min.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Root from "./features/root";
import Manage from "./features/manage";
import Home from "./features/home";
import {DictionaryContextProvider} from "./contexts/dictionary";
import {StateInspector} from "reinspect";
import {LocaleContext, LocaleContextProvider} from "./contexts/localContext";
import {ModalContextProvider} from "./contexts/modalContext";
import {useContext} from "react";
import {SettingsContextProvider} from "./contexts/settingsContext";

function App() {
    // const {showReload, waitingWorker, reloadPage} = useServiceWorker();

    const router = createBrowserRouter([
        {
            path: '/',
            element: <Root />,
            children: [
                {
                    path: '',
                    element: <Home />
                    // element: <div>Coucou</div>
                }, {
                    path:'manage',
                    element: <Manage />
                }
            ]
        }
    ]);

    return <>
        <StateInspector name={"DictionaryState"}>
            <LocaleContextProvider>
                <SettingsContextProvider>
                    <ModalContextProvider>
                        <DictionaryContextProvider>
                            <RouterProvider router={router} />
                        </DictionaryContextProvider>
                    </ModalContextProvider>
                </SettingsContextProvider>
            </LocaleContextProvider>
        </StateInspector>
    </>;
}

export default App;
