export const actions = {
    GET_WORDS: "GET_WORDS",
    GET_THEMES: "GET_THEMES",
    GET_SUBTHEMES: "GET_SUBTHEMES",
    GET_TYPES: "GET_TYPES",
    UPDATE_INPUT_SEARCH: "UPDATE_INPUT_SEARCH",
    UPDATE_THEME: "UPDATE_THEME",
    UPDATE_LETTER: "UPDATE_LETTER",
    UPDATE_SUBTHEME: "UPDATE_SUBTHEME",
    UPDATE_TYPE: "UPDATE_TYPE"
}

export const dictionaryReducer = (state, action) => {
    switch (action.type) {
        case actions.GET_WORDS:
            return {
                ...state,
                data: {
                    ...state.data,
                    words: action.value
                }
            };
        case actions.GET_THEMES:
            return {
                ...state,
                data: {
                    ...state.data,
                    themes: action.value
                }
            };
        case actions.GET_SUBTHEMES:
            return {
                ...state,
                data: {
                    ...state.data,
                    subthemes: action.value
                }
            };
        case actions.GET_TYPES:
            return {
                ...state,
                data: {
                    ...state.data,
                    types: action.value
                }
            };
        case actions.UPDATE_INPUT_SEARCH:
            return {
                ...state,
                search: {
                    ...state.search,
                    inputValue: action.value
                }
            };
        case actions.UPDATE_THEME:
            return {
                ...state,
                search: {
                    ...state.search,
                    theme: action.value
                }
            };
        case actions.UPDATE_SUBTHEME:
            return {
                ...state,
                search: {
                    ...state.search,
                    subtheme: action.value
                }
            };
        case actions.UPDATE_LETTER:
            return {
                ...state,
                search: {
                    ...state.search,
                    letter: action.value
                }
            };
        case actions.UPDATE_TYPE:
            return {
                ...state,
                search: {
                    ...state.search,
                    wordType: action.value
                }
            };

        default: return state;
    }
}
