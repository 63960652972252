import {localeInit} from "../helpers/localeInit";

export const actions = {
    INIT_LOCALE: "INIT_LOCALE",
    UPDATE_LOCALE: "UPDATE_LOCALE"
};

export const localeReducer = (state, action) => {
    switch (action.type) {
        case actions.INIT_LOCALE:
            const locale = localeInit();
            return {
                locale
            };
        case actions.UPDATE_LOCALE:
            return {
                ...state,
                locale: action.value
            };
        default: return state;
    }
}
