import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import "./add-word.scss";

const AddWord = ({themes, subthemes, types}) => {
    const {register, handleSubmit, getValues, setValue, formState: {errors}} = useForm({
        defaultValues: {
            theme: null,
            subtheme: null,
            type: null,
            verbData: null,
            translation: {
                word: undefined,
                hiragana: undefined,
                romaji: undefined
            }
        }
    });

    const [themeId, setThemeId] = useState(undefined);
    const [typeId, setTypeId] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const onSubmit = async (data) => {
        console.log(getValues('theme'));
        console.table(data);

        try {
            const res = await (await fetch(`${process.env.REACT_APP_BACKEND_URL}/words/add`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                mode: 'cors',
                body: JSON.stringify({
                    word: data
                })
            })).json();
            console.log('res', res);
        } catch(e) {
            throw new Error(e);
        }
    };

    // Check for existing languages, then add X property in labels object
    const setLabelLangs = useCallback(() => {
        const data = {};
        for (const lang in langs) {
            data[langs[lang]] = ''
        }

        return data;
    }, [],);

    // remove this when the language system will be ready
    const langs = ['fr', 'en'];

    useEffect(() => {
        const data = setLabelLangs();

        if (!label) {
            setLabel(data);
        }
    }, []);

    const themeChangeHandle = useCallback((e) => {
        setValue('theme', e.target.value);
        setValue('subtheme', undefined);
        setThemeId(e.target.value);
    }, [setValue, setThemeId]);

    const typeChangeHandle = useCallback((e) => {
        setValue('verb.group', e.target.value);
        setTypeId(e.target.value);
    }, []);

    const renderLabelsInput = useCallback(() => {
        if (!label) {
            return null;
        }

        const keys = Object.keys(label);

        return keys.map((k) => {
            return <div key={`label--${k}`}>
                <div>
                    <label htmlFor={`input-${k}`}>{k}: </label>
                </div>
                <input id={`input-${k}`} placeholder={k.toUpperCase()} type="text" {...register(`label[${k}]`, {required: true})} />
            </div>;
        });
    }, [label]);

    const renderRadioInputs = useCallback((name, data, requiredName = undefined, changeHandle = undefined) => {
        return data.map((item) => {
            return <div key={`radio-value-${name}-${item._id}`}>
                <input id={`radio-${name}-${item._id}`} type="radio"
                       value={item._id}
                       {...register(`${name}`, {
                           required: name !== requiredName === undefined ? 'subtheme' : requiredName,
                           onChange: changeHandle
                       })}
                />
                <label htmlFor={`radio-${name}-${item._id}`}>{item.label.fr}</label>
            </div>
        });
    }, []);

    const renderSubthemes = useCallback(() => {
        if (!themeId) {
            return null;
        }

        const subtheme = subthemes.find((e) => e.theme_id === themeId);

        if (!subtheme) {
            return null;
        }

        return <div>
            <div>Sous thème</div>
            {renderRadioInputs('subtheme', subtheme.list)}
        </div>;
    }, [themeId, subthemes]);

    const renderVerbOptions = useCallback(() => {
        const type = types.find((t) => t.type === 'verb');

        if (!type) {
            return null;
        }

        if (typeId !== type._id) {
            return null;
        }

        return <div>
            <div>
                <div>
                    <div>Groupe</div>
                    <div>
                        <label htmlFor="godan">Godan</label>
                        <input type="radio" value={"godan"} id={"godan"} {...register('verbData.group', {required: type === type._id})}/>
                        <label htmlFor="ichidan">Ichidan</label>
                        <input type="radio" value={"ichidan"} id={"ichidan"} {...register('verbData.group', {required: type === type._id})}/>
                        <label htmlFor="irregular">irregular</label>
                        <input type="radio" value={"irregular"} id={"irregular"} {...register('verbData.group', {required: type === type._id})}/>
                    </div>
                </div>
                <div>
                    <div>Conjugaison</div>
                    <div>
                        <div>
                            <label htmlFor="jishokei">Forme du dictionnaire</label>
                            <input id={"jishokei"}  type="text" {...register('verbData.conjugation.jishokei', {required: type === type._id})}/>
                        </div>
                        <div>
                            <div>Present / Futur</div>
                            <label htmlFor="presentPolite">Forme polie</label>
                            <input id={"presentPolite"}  type="text" {...register('verbData.conjugation.present.polite.positive', {required: type === type._id})}/>
                            <label htmlFor="presentPoliteNegative">Forme polie négatif</label>
                            <input id={"presentPoliteNegative"}  type="text" {...register('verbData.conjugation.present.polite.negative', {required: type === type._id})}/>

                            <label htmlFor="presentPolitePositive">Forme neutre</label>
                            <input id={"presentPolitePositive"}  type="text" {...register('verbData.conjugation.present.neutral.positive', {required: type === type._id})}/>
                            <label htmlFor="presentNeutralNegative">forme neutre négative</label>
                            <input id={"presentNeutralNegative"}  type="text" {...register('verbData.conjugation.present.neutral.negative', {required: type === type._id})}/>

                            <div>Passé</div>
                            <label htmlFor="pastPolite">Forme polie</label>
                            <input id={"pastPolite"}  type="text" {...register('verbData.conjugation.past.polite.positive', {required: type === type._id})}/>
                            <label htmlFor="pastPoliteNegative">Forme polie négatif</label>
                            <input id={"pastPoliteNegative"}  type="text" {...register('verbData.conjugation.past.polite.negative', {required: type === type._id})}/>

                            <label htmlFor="pastPolitePositive">Forme neutre</label>
                            <input id={"pastPolitePositive"}  type="text" {...register('verbData.conjugation.past.neutral.positive', {required: type === type._id})}/>
                            <label htmlFor="pastNeutralNegative">forme neutre négative</label>
                            <input id={"pastNeutralNegative"}  type="text" {...register('verbData.conjugation.past.neutral.negative', {required: type === type._id})}/>

                            <div>Forme en TE</div>
                            <label htmlFor="tekei">Forme en -te</label>
                            <input id={"tekei"}  type="text" {...register('verbData.conjugation.tekei.positive', {required: type === type._id})}/>
                            <label htmlFor="tekeiNegative">Forme en -te négative</label>
                            <input id={"tekeiNegative"}  type="text" {...register('verbData.conjugation.tekei.negative', {required: type === type._id})}/>

                            <div>Volitif</div>
                            <label htmlFor="volitionalPolite">Forme polie</label>
                            <input id={"volitionalPolite"}  type="text" {...register('verbData.conjugation.volitional.polite', {required: type === type._id})}/>
                            <label htmlFor="volitionalNeutral">Forme neutre</label>
                            <input id={"volitionalNeutral"}  type="text" {...register('verbData.conjugation.volitional.neutral', {required: type === type._id})}/>
                        </div>

                        <div>Potentiel</div>
                        <label htmlFor="potentialPolitePositive">Forme polie</label>
                        <input id={"potentialPolitePositive"}  type="text" {...register('verbData.conjugation.potential.polite.positive', {required: type === type._id})}/>
                        <label htmlFor="potentialPoliteNegative">Forme polie négatif</label>
                        <input id={"potentialPoliteNegative"}  type="text" {...register('verbData.conjugation.potential.polite.negative', {required: type === type._id})}/>

                        <label htmlFor="potentialNeutralPositive">Forme neutre</label>
                        <input id={"potentialNeutralPositive"}  type="text" {...register('verbData.conjugation.potential.neutral.positive', {required: type === type._id})}/>
                        <label htmlFor="potentialNeutralNegative">forme neutre négative</label>
                        <input id={"potentialNeutralNegative"}  type="text" {...register('verbData.conjugation.potential.neutral.negative', {required: type === type._id})}/>

                        <div>Passif</div>
                        <label htmlFor="passivePolitePositive">Forme polie</label>
                        <input id={"passivePolitePositive"}  type="text" {...register('verbData.conjugation.passive.polite.positive', {required: type === type._id})}/>
                        <label htmlFor="passivePoliteNegative">Forme polie négatif</label>
                        <input id={"passivePoliteNegative"}  type="text" {...register('verbData.conjugation.passive.polite.negative', {required: type === type._id})}/>

                        <label htmlFor="passiveNeutralPositive">Forme neutre</label>
                        <input id={"passiveNeutralPositive"}  type="text" {...register('verbData.conjugation.passive.neutral.positive', {required: type === type._id})}/>
                        <label htmlFor="passiveNeutralNegative">forme neutre négative</label>
                        <input id={"passiveNeutralNegative"}  type="text" {...register('verbData.conjugation.passive.neutral.negative', {required: type === type._id})}/>

                        <div>Causatif</div>
                        <label htmlFor="causativePolitePositive">Forme polie</label>
                        <input id={"causativePolitePositive"}  type="text" {...register('verbData.conjugation.causative.polite.positive', {required: type === type._id})}/>
                        <label htmlFor="causativePoliteNegative">Forme polie négatif</label>
                        <input id={"causativePoliteNegative"}  type="text" {...register('verbData.conjugation.causative.polite.negative', {required: type === type._id})}/>

                        <label htmlFor="causativeNeutralPositive">Forme neutre</label>
                        <input id={"causativeNeutralPositive"}  type="text" {...register('verbData.conjugation.causative.neutral.positive', {required: type === type._id})}/>
                        <label htmlFor="causativeNeutralNegative">forme neutre négative</label>
                        <input id={"causativeNeutralNegative"}  type="text" {...register('verbData.conjugation.causative.neutral.negative', {required: type === type._id})}/>

                        <div>Impératif</div>
                        <label htmlFor="imperativePolitePositive">Forme polie</label>
                        <input id={"imperativePolitePositive"}  type="text" {...register('verbData.conjugation.imperative.polite.positive', {required: type === type._id})}/>
                        <label htmlFor="imperativePoliteNegative">Forme polie négatif</label>
                        <input id={"imperativePoliteNegative"}  type="text" {...register('verbData.conjugation.imperative.polite.negative', {required: type === type._id})}/>

                        <label htmlFor="imperativeNeutralPositive">Forme neutre</label>
                        <input id={"imperativeNeutralPositive"}  type="text" {...register('verbData.conjugation.imperative.neutral.positive', {required: type === type._id})}/>
                        <label htmlFor="imperativeNeutralNegative">forme neutre négative</label>
                        <input id={"imperativeNeutralNegative"}  type="text" {...register('verbData.conjugation.imperative.neutral.negative', {required: type === type._id})}/>

                        <div>Conditionnel</div>
                        <label htmlFor="conditionalPositive">Forme polie</label>
                        <input id={"conditionalPositive"}  type="text" {...register('verbData.conjugation.conditional.positive', {required: type === type._id})}/>
                        <label htmlFor="conditionalNegative">Forme polie négatif</label>
                        <input id={"conditionalNegative"}  type="text" {...register('verbData.conjugation.conditional.negative', {required: type === type._id})}/>

                        <div>Conditionnel (-tara)</div>
                        <label htmlFor="conditionalTaraPositive">Forme polie</label>
                        <input id={"conditionalTaraPositive"}  type="text" {...register('verbData.conjugation.conditionalTara.positive', {required: type === type._id})}/>
                        <label htmlFor="conditionalTaraNegative">Forme polie négatif</label>
                        <input id={"conditionalTaraNegative"}  type="text" {...register('verbData.conjugation.conditionalTara.negative', {required: type === type._id})}/>
                    </div>
                </div>
            </div>
        </div>
    }, [types, typeId]);

    return <div>
        <form onSubmit={handleSubmit(onSubmit)} className={"add-word"}>
            <div>
                {renderLabelsInput()}
            </div>
            <div>
                <div>
                    <div>Theme</div>
                    <div>
                        {renderRadioInputs('theme', themes, undefined, themeChangeHandle)}
                    </div>
                </div>
            </div>

            {renderSubthemes()}

            <div>
                <div>
                    <div>Types</div>
                    {renderRadioInputs('type', types, undefined, typeChangeHandle)}
                </div>
            </div>

            {renderVerbOptions()}

            <div>
                <div>Traduction</div>
                <div>
                    <input placeholder={"Word"}     type="text" {...register('translation.word', {required: true})} />
                    <input placeholder={"Hiragana"} type="text" {...register('translation.hiragana', {required: false})}/>
                    <input placeholder={"Romaji"}   type="text" {...register('translation.romaji', {required: true})}/>
                </div>
            </div>

            <input type="submit" value={"balance"}/>
        </form>
    </div>;
}

export default AddWord;
