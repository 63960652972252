import {localeInit} from "../helpers/localeInit";
import {createContext, useReducer} from "react";
import {actions, localeReducer} from "../reducers/localeReducer";
import {LANGUAGES} from "../constants/languages";

export const initialState = {
    locale: localeInit(),
    availableLocales : LANGUAGES,
};

export const LocaleContext = createContext({});

export const LocaleContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(localeReducer, initialState);
    const value = {
        state,
        updateLocale: (value) => dispatch({type: actions.UPDATE_LOCALE, value})
    }

    return <LocaleContext.Provider value={value}>
        {children}
    </LocaleContext.Provider>;
}
