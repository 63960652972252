import {ModalContext} from "../../contexts/modalContext";
import {useCallback, useContext} from "react";

import './modal.scss';
import {LocaleContext} from "../../contexts/localContext";

const Modal = () => {
    const {state: {content, title, open, mode}, toggleModal} = useContext(ModalContext);
    const {state: {locale}} = useContext(LocaleContext);
    const titleTranslated = title ? title[locale] : undefined;

    const handleToggle = useCallback((e) => {
        e.preventDefault();
        toggleModal();
    }, [toggleModal])

    return <div className={`modal ${open ? 'modal--open' : ''}`}>
        <div className={"modal__overlay"} onClick={handleToggle} />
        <div className={`modal__layout modal__layout--${mode}`}>
            <div className="modal__layout__header">
                <h3>{titleTranslated}</h3>
                <a href="#" onClick={handleToggle} className={"modal__layout__close"}>
                    <i className={"gg-close"} />
                </a>
            </div>
            <div className={'modal__layout__content'}>
                {content}
            </div>
        </div>
    </div>;
}

export default Modal
