import './locales-selector.scss';
import {useCallback, useContext} from "react";
import {LocaleContext} from "../../contexts/localContext";

const LocalesSelector = () => {
    const {state: {availableLocales, locale}, updateLocale} = useContext(LocaleContext);

    const handleCta = useCallback((code) => {
        updateLocale(code);
    }, [locale, updateLocale]);

    const renderLocales = useCallback(() => {
        return availableLocales.map((l) => {
            return <li
                className={`locales-selector__item ${locale === l.code ? 'locales-selector__item--selected' : ''}`}
                key={`row--${l.code}`}
                onClick={() => handleCta(l.code)}
            >
                <img src={`${process.env.PUBLIC_URL}/assets/flags/${l.flag}.svg`} alt={`flag-${l.code}`}/>
                <p>{l.label}</p>
            </li>;
        });
    }, [locale, availableLocales]);

    return <ul className={"locales-selector"}>
        {renderLocales()}
    </ul>
}

export default LocalesSelector;
