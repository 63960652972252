import {useContext} from "react";
import {LocaleContext} from "../../contexts/localContext";
import LocalesSelector from "../locales-selector/locales-selector";
import {SettingsContext} from "../../contexts/settingsContext";

import locales from "../../constants/locales.json";
import './settings.scss';

const Settings = () => {
    const {state: {locale}} = useContext(LocaleContext);
    const {state: {kanjiMode, romajiMode}, toggleKanjiMode, toggleRomajiMode} = useContext(SettingsContext);

    return <div>
        <LocalesSelector />
        <div className={"settings"}>
            <div className="settings__item">
                <div>
                    <h4 className={"settings__item__title"}>{locales.settings.showKanji.title[locale]}</h4>
                    <p className={"settings__item__content"}>{locales.settings.showKanji.content[locale]}</p>
                </div>
                <div className={"settings__input-toggle"}>
                    <input
                        type="checkbox"
                        id={"kanjiMode"}
                        value={"true"}
                        className={"settings__item__input-bool"}
                        checked={kanjiMode}
                        onChange={toggleKanjiMode}
                    />
                    <label htmlFor="kanjiMode">Toggle</label>
                </div>
            </div>
            <div className="settings__item">
                <div>
                    <h4 className={"settings__item__title"}>{locales.settings.showRomaji.title[locale]}</h4>
                    <p className={"settings__item__content"}>{locales.settings.showRomaji.content[locale]}</p>
                </div>
                <div className={"settings__input-toggle"}>
                    <input
                        type="checkbox"
                        id={"romajiMode"}
                        value={"true"}
                        className={"settings__item__input-bool"}
                        checked={romajiMode}
                        onChange={toggleRomajiMode}
                    />
                    <label htmlFor="romajiMode">Toggle</label>
                </div>
            </div>
        </div>
    </div>
};


export default Settings;
