import {createContext, useState} from "react";

export const initialState = {
    title: undefined,
    open: false,
    content: undefined,
    mode: 'vertical'
};

export const ModalContext = createContext({});

export const ModalContextProvider = ({children}) => {
    const [state, setState] = useState(initialState);

    const value = {
        state,
        toggleModal: (title = undefined, content = undefined, mode = 'vertical') => setState({
            ...state,
            open: !state.open,
            title,
            mode,
            content
        }),
    };

    return <ModalContext.Provider value={value}>
        {children}
    </ModalContext.Provider>;
}
