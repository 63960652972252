export const actions = {
    TOGGLE_KANJI_MODE: "TOGGLE_KANJI_MODE",
    TOGGLE_ROMAJI_MODE: "TOGGLE_ROMAJI_MODE"
};

export const settingsReducer = (state, action) => {
    switch (action.type) {
        case actions.TOGGLE_KANJI_MODE:
            return {
                ...state,
                kanjiMode: !state.kanjiMode
            };
        case actions.TOGGLE_ROMAJI_MODE:
            return {
                ...state,
                romajiMode: !state.romajiMode
            };
        default: return state;
    }
}
