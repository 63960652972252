import {Outlet} from "react-router-dom";
import Header from "../components/header/header";
import {ToastContainer} from "react-toastify";

import {useContext, useEffect} from "react";
import Modal from "../components/modal/modal";
import {LocaleContext} from "../contexts/localContext";
import {setLocale} from "../helpers/localeInit";
import {SettingsContext} from "../contexts/settingsContext";
import {saveSettings} from "../helpers/settingsInit";

const Root = () => {
    const {state: {locale}} = useContext(LocaleContext);
    const {state} = useContext(SettingsContext);

    useEffect(() => {
        setLocale(locale);
    }, [locale]);

    useEffect(() => {
        saveSettings(state);
    }, [state]);


    return <>
        <Header />
        <main>
            <Outlet />
        </main>
        <Modal />
        <ToastContainer/>
    </>
};

export default Root;
