import React, {useCallback, useContext} from "react";
import {DictionaryContext} from "../../contexts/dictionary";

import "./alphabet.scss";

const Alphabet = () => {
    const {updateLetter} = useContext(DictionaryContext);
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G',
        'H', 'I', 'J', 'K', 'L', 'M', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    const renderAlphabet = useCallback(() => {
        return alphabet.map((a) => {
            const input_id = `letter-${a}`;
            return <div key={input_id} className={"alphabet__case"}>
                <input
                    id={input_id}
                    type="radio"
                    name={"letter"}
                    value={a.toLowerCase()}
                    onChange={(e) => updateLetter(e.target.value)}
                />
                <label htmlFor={input_id}>{a}</label>
            </div>
        });
    }, []);

    return <div className={"alphabet"}>
        {renderAlphabet()}
    </div>
}

export default Alphabet;
