

import './input.scss';

const Input = ({options: {type, handle, icon, label, id, placeholder, defaultValue}}) => {
    // if (label && id) {
    //     return <div>
    //         <label htmlFor={id}>{label}</label>
    //         <input type={type} id={id} />
    //     </div>
    // }

    const renderIcon = () => {
        if (!icon) return null;

        return <div className={"input__icon"}><i className={`gg-${icon}`} /></div>
    }

    const renderLabel = () => {
        if (id && label) {
            return <label className={"input__label"} htmlFor={id}>{label}</label>
        }

        return null;
    }

    return <div className={"input"}>
        {renderLabel()}
        {renderIcon()}
        <input
            type={type}
            id={id}
            className={"input__input"}
            placeholder={placeholder || ''}
            onChange={handle}
            defaultValue={defaultValue}
        />
    </div>;
}

export default Input;
