import {useCallback, useContext} from "react";

import "./words.scss";
import {LocaleContext} from "../../contexts/localContext";
import {SettingsContext} from "../../contexts/settingsContext";

const Words = ({words}) => {
    const {state: {locale}} = useContext(LocaleContext);
    const {state: {kanjiMode, romajiMode}} = useContext(SettingsContext);

    const getWord = useCallback((w) => {
        const {translation: {hiragana, romaji, word}} = w;

        return `${hiragana && !kanjiMode ? hiragana : word}${romajiMode ? ` | ${romaji.toUpperCase()}` : ''}`;
    }, [locale, kanjiMode, romajiMode]);

    const renderWords = useCallback(() => {
        if (!words.length) {
            return null;
        }

        return words.map((w) => {
           return <div key={`word-${w._id}`} className={"words__item"}>
               <div>{w.label[locale]}</div>
               <div>{getWord(w)}</div>
           </div>
        });
    }, [words, locale, kanjiMode, romajiMode]);

    return (
        <div className={"words"}>
            {renderWords()}
        </div>
    )
}

export default Words;
