const compare = (a, b, lang) => {
  if (a.label[lang] < b.label[lang]) {
      return -1;
  }

  if (a.label[lang] > b.label[lang]) {
      return 1;
  }

  return 0;
};

 export const sortWordsArray = (array, lang = localStorage.getItem('locale')) => {
     return array.sort((a,b) => compare(a, b, lang));
 }
