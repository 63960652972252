import {Link} from "react-router-dom";
import {useCallback} from "react";

import './actionButton.scss';

const ActionButton = ({icon, callback, link, fullMode, darkMode}) => {
    const handleClick = useCallback((e) => {
        e.preventDefault();
        callback();
    }, []);

    if (link) {
        return <Link to={link} className={`action-button ${fullMode ? 'action-button--full' : ''} ${darkMode ? 'action-button--dark' : ''}`}>
            <i className={`gg-${icon}`} />
        </Link>;
    }

    return <a href={"#"} onClick={handleClick} className={`action-button ${fullMode ? 'action-button--full' : ''} ${darkMode ? 'action-button--dark' : ''}`}>
        <i className={`gg-${icon}`} />
    </a>;
};

export default ActionButton;
