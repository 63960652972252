import React, {useCallback, useContext} from "react";

import "./header.scss";
import Input from "../input/input";
import {Link} from "react-router-dom";
import Alphabet from "../alphabet/alphabet";
import {DictionaryContext} from "../../contexts/dictionary";
import ActionButton from "../actionButtons/actionButton";

import locales from '../../constants/locales.json';
import {LocaleContext} from "../../contexts/localContext";
import {ModalContext} from "../../contexts/modalContext";
import Settings from "../settings/settings";
const Header = () => {
    const {state: {search}, updateInputSearch} = useContext(DictionaryContext);
    const {state: {locale}} = useContext(LocaleContext);
    const {toggleModal} = useContext(ModalContext);

    const renderTopHeader = useCallback(() => {
        const inputOptions = {
            type: 'text',
            placeholder: locales.header.inputSearch[locale],
            icon: 'search',
            defaultValue: search.inputValue,
            handle: (e) => {
                e.preventDefault();
                updateInputSearch(e.target.value);
            }
        };

        const settingsCtaHandle = useCallback(() => {
            toggleModal(locales.modal.settings.title, <Settings />);
        }, []);

        return (
            <div className={"header__top"}>
                <div>
                    <h1><Link to={'/'}>日本語<span>の</span>辞書</Link></h1>
                    <div className={"header__input"}>
                        <Input options={inputOptions} />
                    </div>
                </div>
                <div>
                    {/*<ActionButton icon={'user'} fullMode={true} />*/}
                    <ActionButton
                        icon={'options'}
                        fullMode={true}
                        darkMode={true}
                        callback={settingsCtaHandle}
                    />
                </div>
            </div>
        )
    }, [locale]);

    return (
        <header className={"header"}>
            {renderTopHeader()}
            <Alphabet/>
        </header>
    );
};

export default Header;
