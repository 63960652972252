export const asciiBank = {
    ONNANOHITO_DEMO: `
    　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　／ '、　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　 ／ '｜ |　　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　　 /''　　 |　|　　　　　 　　　,  ''´ |　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　 　 /　　　 |   |　　　　　　 ／　  '|｜　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　/　　　　 |　| 　　　　 　　 ／　 |｜　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　/′　　 　 |　  　　　／　   　　　| |　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　/′　,,､   '´ |　|　　 /′／　 　 　 /　　　　
　　　　　　　　　　　　　　　　　　　　　　 　 　 /　　 |｛ ′　/　|　  /　 /,,　　　 　 /　　　　　
　　　　　　　　　　　　　　　　　　　　　　　 　 ,|　　 || 　 　 /　|　 /　 /''/　　　 　 |　　　　　
　　　　　　　　　　　　　　　　　　　　　　　 　 |　　　　　　/　 / /′ / ,,,'′ 　 　 |　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　 __ﾉ_..-‐‐''￣ﾆﾆ''''′､__/ 　 彡　　　 /　　　　　　
　　　　　　　　　　　　　　　　　　　　　_ ''´／　　　　　 ,  ,､　　_, 　＼、／　 　 /　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　 ''　　　 　 / /／{｜/,,| 、　　 ＼　　/′　　　　　　
　　　　　　　　　　　　　　　 　 　 /,　,'' 　　　 /　/|　´　　\`┴  、|　　　　＼/　　　　　　　　
　　　　　　　　　　　　　　　　　//'' /　 　 　 /　| |　　　　　　　 ＼　　　　  |ﾄ　　　　　　　　
　　　　　　　　　　　　　　　　 //　/　　　　 ,| ‐┼|　　　　　 　 ,　 /　 　　　| ∨　　　　　　　
　　　　　　　　　　　　　　　 // 　 |　　　　 ,//   ｜ i　　 　 　 ,//′　| 　 　 | ｝　　　　　　　
　　　　　　　　　　　　　 　 / ′　 ､　 　 /  ', -‐-､＼　　 　 /′\`\`　 ′　　 |　|　　　　　　　
　　　　　　　　　　　　　　 || |　　　 ＼　/　/ r-' ヽ  ﾞ|　 　 ,厶　　 | /　　 　 |　||　　　　　　
　　　　　　　　　　　　　　 |｜　　　 | ∨ | 　   　 /　｜  ／_｢\`＼ :| |　　　　,|　||　　　　　　
　　　　　　　　　　　　　　 |人　　　 |　  |　　 ー‐　　   　|′　|  | ,/|　　　 ,ﾉ　| |　　　　　　
　　　　　　　　　　　　　　　　＼　  ‐､　 ､　　　　　　　　  ､_. '´　 ′　_.. '´　　|,|　　　　　　　
　　　　　　　　　　　　　　　　　    ,|-‐|､､ﾐ ､_　 〔ﾆﾆ_､　　　　　 ,,, ''''´´　　　 /′　　　　　　
　　　　　　　　　　　　　　　　 　 /　　||l　 ＼,〉｀\`ｰ､‐‐′　 ___ 　 ,／　　 　 //　　　　　　　
　　　　　　　　　　　　　　　 　 _,_＿ 　|　 ／ 　　　 |¨丁￣￣|-'^'７　　　__ /′　　　　　　　
　　　　　　　　　　　　　 　 /´´　　 ｀ |　〈＿＿＿_____ﾉ　 　 | |＼｛,,..-‐´´|'′　　　　　　　　
　　　　　　　　　　　　　 　 |　　 　　　|　　　　　 | |　  \`ｰ､, .' |　 i,　　　　　　　　　　　　　　　
　　　　　　　　　　　　　 　 |　　　　 /′　　　 |　|| 　 　 /〕__/-┘　　　　　　　　　　　　　　
　　　　  ―--＿..＿_ 　 　'｀　　　/′　　　　｜:|| 　 　 /￣ ￣　　　　　　　　　　　　　　　　
　　　　 ＼-＿____　 ｀＼　　　　 l,|　　 /　　　  ﾉ′ 　 |　　 /′　　　　　　　　　　　　　　　
　　　 ,"￣￣￣_ ＼__　　  _　　/ﾄ､､_   　 _rr／　   　ﾉ 　 /　　　　　　　　　　　　　　　　　
　　　\`'´´　 ´￣　　 '  ,　　     ,,　\`｀ ≧三三三__,,イ　 　 j　　　　　　　　　　　　　　　　　　
　　　　　 （-‐'''´´/　/　-‐"　/ 、　　　￣￣￣ﾆﾆ‐-､　 ﾞ{　　　　　　　　　　　　　　　　　　
. _..─‐-､＿＿__( ,,.-'ﾆ´--´´　　　　　　　　　 '''¨　　 ＼　}　　　　　　　　　　　　　　　　　　
　　　　　　　　　｀　　　　　　　　__　　　　　　 __　　　　　＼|　　　　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　 ｀'ｰ―'´´￣　　　　　　　 |　　　　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　 |　　　　　　　　　　　　　　　　　　
　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　
    
    `,
CARAPUCE_ZENIGAME: `
             _,........__
            ,-'            "\`-.
          ,'                   \`-.
        ,'                        \\
      ,'                           .
      .'\\               ,"".       \`
     ._.'|             / |  \`       \\
     |   |            \`-.'  ||       \`.
     |   |            '-._,'||       | \\
     .\`.,'             \`..,'.'       , |\`-.
     l                       .'\`.  _/  |   \`.
     \`-.._'-   ,          _ _'   -" \\  .     \`
\`."""""'-.\`-...,---------','         \`. \`....__.
.'        \`"-..___      __,'\\          \\  \\     \\
\\_ .          |   \`""""'    \`.           . \\     \\
  \`.          |              \`.          |  .     L
    \`.        |\`--...________.'.        j   |     |
      \`._    .'      |          \`.     .|   ,     |
         \`--,\\       .            \`7""' |  ,      |
            \` \`      \`            /     |  |      |    _,-'"""\`-.
             \\ \`.     .          /      |  '      |  ,'          \`.
              \\  v.__  .        '       .   \\    /| /              \\
               \\/    \`""\\"""""""\`.       \\   \\  /.''                |
                \`        .        \`._ ___,j.  \`/ .-       ,---.     |
                ,\`-.      \\         ."     \`.  |/        j     \`    |
               /    \`.     \\       /         \\ /         |     /    j
              |       \`-.   7-.._ .          |"          '         /
              |          \`./_    \`|          |            .     _,'
              \`.           / \`----|          |-............\`---'
                \\          \\      |          |
               ,'           )     \`.         |
                7____,,..--'      /          |
                                  \`---.__,--.'

`
};
