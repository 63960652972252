export const LANGUAGES = [
    {
        code: 'fr',
        label: 'Français',
        flag: 'fr'
    }, {
        code: 'en',
        label: 'English',
        flag: 'gb'
    }
]
