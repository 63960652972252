export const settingsInit = () => {
    let settingsStored = JSON.parse(localStorage.getItem('辞書の初期設定')) || undefined;

    if (!settingsStored) {
        settingsStored = {
            kanjiMode: true,
            romajiMode: true
        };

        localStorage.setItem('辞書の初期設定', JSON.stringify(settingsStored));
    }

    return settingsStored;
}

export const saveSettings = (settings) => localStorage.setItem('辞書の初期設定', JSON.stringify(settings));
