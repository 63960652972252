import {createContext, useEffect, useReducer} from "react";
import {actions, dictionaryReducer} from "../reducers/dictionaryReducer";
import {getSimple} from "../helpers/get";


export const initialState = {
    search: {
        inputValue: '',
        theme: {
            _id: '',
            type: 'all',
            label: {
                fr: 'tout',
                en: 'all'
            }
        },
        letter: '',
        subtheme: '',
        wordType: ''
    },
    data: {
        words: [],
        themes: [],
        subthemes: [],
        types: []
    }
};

export const DictionaryContext = createContext({});

export const DictionaryContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(dictionaryReducer, initialState);
    const value = {
        state,
        getWords: (value) => dispatch({type: actions.GET_WORDS, value}),
        getThemes: (value) => dispatch({type: actions.GET_THEMES, value}),
        getSubthemes: (value) => dispatch({type: actions.GET_SUBTHEMES, value}),
        getTypes: (value) => dispatch({type: actions.GET_TYPES, value}),
        updateInputSearch: (value) => dispatch({type: actions.UPDATE_INPUT_SEARCH, value}),
        updateTheme: (value) => dispatch({type: actions.UPDATE_THEME, value}),
        updateLetter: (value) => dispatch({type: actions.UPDATE_LETTER, value}),
        updateSubtheme: (value) => dispatch({type: actions.UPDATE_SUBTHEME, value}),
        updateType: (value) => dispatch({type: actions.UPDATE_TYPE, value})
    };

    useEffect(() => {
        const getData = async () => {
            const [wordsData, themesData, subthemesData, typesData] = await Promise.all([
                await getSimple('words'),
                await getSimple('themes'),
                await getSimple('subthemes'),
                await getSimple('types'),
            ]);

            if (wordsData.ok && !state.data.words.length) {
                dispatch({type: actions.GET_WORDS, value: wordsData.words});
            }

            if (themesData.ok && !state.data.themes.length) {
                dispatch({type: actions.GET_THEMES, value: themesData.themes});
            }

            if (subthemesData.ok && !state.data.subthemes.length) {
                dispatch({type: actions.GET_SUBTHEMES, value: subthemesData.subthemes});
            }

            if (typesData.ok && !state.data.types.length) {
                dispatch({type: actions.GET_TYPES, value: typesData.types});
            }
        }

        getData();
    }, []);

    return <DictionaryContext.Provider value={value} displayName={"DictionaryState"}>
        {children}
    </DictionaryContext.Provider>;
};
