const getLocale = (lang) => lang.indexOf('fr') !== -1 ? 'fr' : 'en';

export const localeInit = () => {
    let localeStored = localStorage.getItem('前後');

    if (!localeStored) {
        localeStored = getLocale(navigator.language);
        localStorage.setItem('前後', localeStored);
    }

    return localeStored;
};

export const setLocale = (locale) => localStorage.setItem('前後', locale);
