export const getSimple = async (pathname) => {
    if (!pathname || !pathname.length) {
        return {
            ok: false,
            message: 'Wrong pathname, please try again.'
        };
    }

    return await (await fetch(`${process.env.REACT_APP_BACKEND_URL}/${pathname}`, {
        method: 'GET',
        mode: 'cors'
    })).json();
}
