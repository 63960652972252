import {useCallback, useEffect, useState} from "react";

import './dictionary.scss';
import AddWord from "../add-word/add-word";

const Dictionary = () => {
    const [words, setWords] = useState([]);
    const [themes, setThemes] = useState([]);
    const [types, setTypes] = useState([]);
    const [subthemes, setSubthemes] = useState([]);
    const [addWordData, setAddWordData] = useState({})
    const [showAddWord, setShowAddWord] = useState(false);

    useEffect(() => {
        const dataFetch = async () => {
            const properties = ['themes', 'subthemes', 'types', 'words'];

            for (const property of properties) {
                const data = await (await fetch(`${process.env.REACT_APP_BACKEND_URL}/${property}`, {
                    method: 'GET',
                    mode: 'cors'
                })).json();

                if (data.ok) {
                    switch (property) {
                        case 'themes':
                            if (!themes.length) {
                                setThemes(data[property]);
                            }
                            break;
                        case 'subthemes':
                            if (!subthemes.length) {
                                setSubthemes(data[property]);
                            }
                            break;
                        case 'types':
                            if (!types.length) {
                                setTypes(data[property]);
                            }
                            break;
                        default:
                            if (!words.length) {
                                setWords(data[property]);
                            }
                            break;
                    }
                }
            }
        };

        dataFetch();
    }, []);

    const handleAddWord = useCallback((e) => {
        e.preventDefault();
        console.log('coucou')
    }, []);

    return <div className={"dictionary"}>
        <section className={"dictionary__section"}>
            <div>{words.length} mots</div>
            <div>
                <button onClick={handleAddWord}>Ajouter un mot</button>
            </div>
        </section>
        <section className={"dictionary__section"}>
            <div>{types.length} types</div>
            <div></div>
        </section>
        <section className={"dictionary__section"}>
            <div>{themes.length} thèmes</div>
            <div></div>
        </section>
        <section className={"dictionary__section"}>
            <div>{subthemes.length} sous-thèmes</div>
            <div></div>
        </section>

        <section className={"dictionary__add"}>
            <AddWord themes={themes} subthemes={subthemes} types={types} />
        </section>
    </div>
};

export default Dictionary;
