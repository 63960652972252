import {createContext, useReducer} from "react";
import {actions, settingsReducer} from "../reducers/settingsReducer";
import {settingsInit} from "../helpers/settingsInit";

export const SettingsContext = createContext({});

export const SettingsContextProvider = ({children}) => {
    const [state, dispatch] = useReducer(settingsReducer, settingsInit());
    const value = {
        state,
        toggleKanjiMode: () => dispatch({type: actions.TOGGLE_KANJI_MODE}),
        toggleRomajiMode: () => dispatch({type: actions.TOGGLE_ROMAJI_MODE}),
    }

    return <SettingsContext.Provider value={value}>
        {children}
    </SettingsContext.Provider>;
}
